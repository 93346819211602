body {
}

.tableCellActions {
    display: flex;
    justify-content: flex-end;
}

.MuiChip-root {
    height: 21px !important;
}

.MuiChip-deleteIcon {
    width: 18px;
    height: 18px;
}